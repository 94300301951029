import React from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Field } from "formik"
import Box from "shared/components/Box"
import Text from "shared/components/Text"
import Button from "shared/components/Button"
import Select from "shared/components/FormSelect"
import { List, ListItem } from "shared/containers/List"
import { RemovalLink } from "./styles"
import { FormikTextAreaField } from "../TextArea"
import FETCH_CAMPAIGN_TOPICS from "mill/graphql/FetchCampaignTopics"
import { md, lg, xl } from "../../utils/breakpoints"

const StatementBox = styled.div`
   {
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    @media ${md} {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 0;
    }
  }
`
const StatementTextBox = styled.div`
   {
    padding-top: 0.55rem;
    flex-shrink: 0;
    margin-bottom: 1rem;
    width: 28rem;
    @media ${md} {
      margin-bottom: 0;
      padding-right: 0.5rem;
    }
  }
`
const TextAreaBox = styled.div`
   {
    flex-grow: 1;
    textarea {
      min-height: 7.8rem;
      @media ${md} {
        min-height: 5.8rem;
      }
      @media (min-width: 73em) {
      min-height: 3.5rem;
      }
    }
  }
`

const ConfidenceStatementInput = ({ form, remove, push }) => {
  const {
    values: { statements }
  } = form
  const { id: campaignId } = useParams()

  // can this be passed down from parent component as this query is already being called on the page?
  const { data: topicData } = useQuery(FETCH_CAMPAIGN_TOPICS, {
    variables: { campaignId: campaignId }
  })
  const topicOptions =
    topicData?.campaign?.topics.map(topic => ({
      label: topic.name,
      value: topic.id
    })) || []

  const handleRemoveStatement = (e, index) => {
    e.preventDefault()
    const statement = form.values.statements[index]
    if (statement.id) {
      // If the link has an ID, then hide the form and set destroy to true
      form.setFieldValue(`statements.${index}._destroy`, "true")
    } else {
      // Else, remove the field
      remove(index)
    }
  }

  const handleAddStatement = e => {
    e.preventDefault()
    const newCount = statements.length + 1

    if (newCount <= 15) {
      push({
        statement: "",
        topicIds: []
      })
    }
  }

  return (
    <Box paddingTop="medium" paddingBottom="medium">
      <List>
        {statements.map((statement, index) => (
          <ListItem
            key={index}
            hidden={statement._destroy}
            data-qa="survey-statement">
            <Box
              padding="medium"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              flexDirection="row"
              flexWrap="wrap">
              <Field name={`statements.${index}.id`} type="hidden" />
              <Field name={`statements.${index}._destroy`} type="hidden" />
              <StatementBox>
                <StatementTextBox>
                  <Text>As of today, I am confident that I can</Text>
                </StatementTextBox>
                <TextAreaBox>
                  <Field
                    name={`statements.${index}.statement`}
                    type="textarea"
                    rows="1"
                    component={FormikTextAreaField}
                    placeholder="[Complete the sentence with a specific learning objective, e.g., explain the key benefits of microlearning]"
                    value={statement.statement}
                    autogrow
                    omitPaddingTop
                  />
                </TextAreaBox>
              </StatementBox>
              <Box fullWidth>
                <Box fullWidth marginTop="xsmall">
                  <Text>Related topics:</Text>
                </Box>
                <Box
                  fullWidth
                  display="flex"
                  flexWrap="nowrap"
                  alignItems="self-end"
                  justifyContent="space-between">
                  <Box fullWidth maxWidth="medium" marginTop="xsmall">
                    <Field
                      name={`statements.${index}.topicIds`}
                      component={Select}
                      isMulti
                      isClearable
                      options={topicOptions}
                    />
                  </Box>

                  <RemovalLink
                    href="#"
                    onClick={e => handleRemoveStatement(e, index)}>
                    Remove
                  </RemovalLink>
                </Box>
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
      <Box paddingTop="medium">
        <Button
          color="primary"
          variant="hollow"
          data-qa="add-statement"
          onClick={handleAddStatement}
          label={"Add statement"}
          disabled={statements.length === 15}
        />
      </Box>
    </Box>
  )
}

export default ConfidenceStatementInput
