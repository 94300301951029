import styled from "styled-components"
import { FormikTextField as TextField } from "mill/components/TextField"
import { sm } from "mill/utils/breakpoints"

export const RemovalLink = styled.a`
  color: ${props => props.theme.colors.error};
  font-size: 1.4rem;
  margin-left: 1.5rem;

  &:hover {
    cursor: pointer;
  }
`

export const TextInput = styled(TextField)`
  @media ${sm} {
    min-width: 32rem;
  }
`
